import React, { useState } from "react";
import logo from "../../src/signinlogo.png";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { isEmpty, isLength } from "../components/helper/Validate";
// import { instance as axios } from "../api/axiosConfig";
import axios from "axios";
import { useParams } from "react-router-dom";
import Input from "./Input";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const initialState = {
  password: "",
};

const ResetPassword = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const { password } = data;
  const { token } = useParams(); // The token will be in the link, so we can get it using useParams() like this:
  // http://localhost:3000/api/v1/auth/reset-password/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9

  const navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  // To clear up the form after the submission successful
  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, password: "" });
  };

  const goToSignin = () => {
    navigate("/signin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check fields
    if (isEmpty(password))
      return message.error("Please enter the password");
    if (isLength(password))
      return message.error("Password must be at least 6 characters long");
    try {
      // await axios.post(`/v1/auth/reset_pass`, { password });
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/v1/auth/reset_pass`,
            { password },
            {
              headers: { Authorization: token },
            }
          );
      handleReset();
      // goToSignin();
      return message.success("Password was successfully changed");
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

  return (
      <section className="h-full gradient-form bg-gray-200 ">
        <div className="container py-8 px-6 h-full lg:max-w-[700px] md:max-w-[500px] sm:max-w-[600px] mx-auto my-auto bg-gray-200">
          <div className="flex justify-center items-center flex-wrap min-h-screen g-6 text-gray-800 bg-gray-200">
            <div className="sm:w-6/10">
              <div className="block bg-white shadow-lg rounded-lg">
                <div className="lg:flex lg:flex-wrap g-0">
                  <div className="lg:w-full px-4 md:px-0">
                    <div className="md:p-12 md:mx-6 mx-6 p-5">
                      <div className="text-center pt-3">
                        <img className="mx-auto w-20" src={logo} alt="logo" />
                        <h4 className="text-xl font-semibold mt-1 mb-12 pt-1">
                          Enter your new password
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                          <Input
                            name="password"
                            handleChange={handleChange}
                            type={visible ? "text" : "password"}
                            placeholder="Password"
                            icon={
                              visible ? <MdVisibility /> : <MdVisibilityOff />
                            }
                            handleClick={handleClick}
                          />
                        </div>
                        {/* <div className="mb-4">
                        <Input
                          type={visible ? "text" : "password"}
                          placeholder="Confirm Password"
                          handleClick={handleClick}
                        />
                      </div> */}

                        <div className="text-center pt-1 mb-3 pb-0">
                          <button
                            className="inline-block px-6 py-2.5 bg-[#001C42] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-0"
                            type="submit"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                          >
                            Reset
                          </button>
                        </div>
                        <div
                          onClick={goToSignin}
                          className="text-blue-500 font-semibold text-center cursor-pointer"
                        >
                          Click here to login
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};
export default ResetPassword;
