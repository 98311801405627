import logo from "../../../src/signinlogo.png";
import { isEmpty, isEmail } from "../helper/Validate";
import { instance as axios } from "../../api/axiosConfig";
import Input from "../Input";
import { useState } from "react";
import { message } from "antd";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  // To clear up the form after the submission successful
  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setEmail({ email: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Check fields
    if (isEmpty(email))
      return message.error("Please fill in all fields");
    //Check email
    if (!isEmail(email))
      return message.error("Please enter a valid email address");
    try {
      await axios.post(`/v1/auth/forgot_pass`, { email });
      handleReset();
      return message.success("Please check your email");
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

  return (
      <section className="h-full gradient-form bg-gray-200 ">
        <div className="container py-8 px-6 h-full lg:max-w-[700px] md:max-w-[500px] sm:max-w-[600px] mx-auto my-auto bg-gray-200">
          <div className="flex justify-center items-center flex-wrap min-h-screen g-6 text-gray-800 bg-gray-200">
            <div className="sm:w-6/10">
              <div className="block bg-white shadow-lg rounded-lg">
                <div className="lg:flex lg:flex-wrap g-0">
                  <div className="lg:w-full px-4 md:px-0">
                    <div className="md:p-12 md:mx-6 mx-6 p-5">
                      <div className="text-center pt-3">
                        <img className="mx-auto w-20" src={logo} alt="logo" />
                        <h4 className="text-xl font-semibold mt-1 mb-12 pt-1">
                          Please enter your email
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                          <Input
                            type="email"
                            placeholder="Email"
                            handleChange={handleChange}
                          />
                        </div>

                        <div className="text-center pt-1 mb-12 pb-1">
                          <button
                            type="submit"
                            className="inline-block px-6 py-2.5 bg-[#001C42] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                          >
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};
export default ForgotPassword;
