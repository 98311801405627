

const Input = ({icon, handleClick, type, name, handleChange, defaultValue, disabled, placeholder}) => {
  return (
    <>
      <div className="mb-4">
        <label>
          <span
            onClick={handleClick}
            className="flex flex-col items-end cursor-pointer"
          >
            {icon}
          </span>
        </label>
        <input
          type={type}
          name={name}
          onChange={handleChange}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        />
      </div>
    </>
  );
}

export default Input