import React, { useState, useEffect } from "react";
import { instance as axios, instanceGetToken } from "../api/axiosConfig";
import {  useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetLoading } from "../redux/loadersSlice";
import {
  SetIsLoggedIn,
  SetToken,
  SetUser,
  SetStripePlanInfo,
} from "../redux/usersSlice";
import Cookies from "js-cookie";

const ProtectedRoute = (props) => {
  const { user, reRender } = useSelector((state) => state.users);
    const [localToken, setLocalToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

      // useEffect(() => {
      //   const _appSignin = localStorage.getItem("_appSignin");

      //   if (_appSignin) {
      //     const getToken = async () => {
      //       const res = await instanceGetToken.post(`/v1/auth/access`);
      //       localStorage.setItem("token", res.data.ac_token);
      //       dispatch(SetToken(res.data.ac_token)); // We want to store this access token in the global access state so the user will already have this when they signin
      //       setLocalToken(res.data.ac_token);
      //     };
      //     getToken();
      //   } else {
      //     // navigate("/signin");
      //   }
      // }, [dispatch]);

  // Here we are setting the user if the token is present, also we are calling the user in the if condition too
  const getUser = async() => {
    try {
        dispatch(SetLoading(true))
        const response = await axios.get('/v1/auth/userInfo');
        dispatch(SetLoading(false));
        if(response.status===200){
            dispatch(SetUser(response.data))
        } else{
              // Cookies.remove("_apprftoken");
              // Cookies.remove("token");
              // dispatch(SetIsLoggedIn(false));
              // dispatch(SetToken(""));
              // localStorage.removeItem("_appSignin");
              navigate('/signin')
        }
    } catch (error) {
         dispatch(SetLoading(false));
        //  Cookies.remove("_apprftoken");
        //  Cookies.remove("token");
        //  dispatch(SetIsLoggedIn(false));
        //  dispatch(SetToken(""));
        //  localStorage.removeItem("_appSignin");
         navigate("/signin");
    }
  }
  useEffect(() => {
    if(!user){
        getUser()
    }
  }, [user, reRender])



    // const getStripePlan = async () => {
    //   const res = await axios.get('/getstripeplaninfo');
    //   console.log("stripe Plan info", res.data);
    //   dispatch(SetStripePlanInfo(res.data));
    // };

    // useEffect(() => {
    //     if(token){
    //         getStripePlan()
    //     }
    // }, [token])

  if(localStorage.getItem("_appSignin")){
    return props.children
  }else{
   return <Navigate to="/signin" />
  }
}

export default ProtectedRoute;