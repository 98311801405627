import { instance as axios } from "../api/axiosConfig";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDateFormat, secondsToHours } from "./utils/helpers";

const Search = () => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState([]);
  const query = useLocation().search; // This is the query string (?) included in the URL.
  useEffect(() => {
    const fetchProjects = async () => {
      const res = await axios.get(`/projects/search${query}`); // This input is being navigated from Navbar.js search section
      setProjects(res.data);
      console.log("Search is" + res.data);
    };
    fetchProjects();
  }, [query]);
  return (
    <>
      {/* <ul
        className="nav row row-cols-1 gy-4 
            row-cols-sm-2 row-cols-lg-3 row-cols-xxl-4 px-3 py-3"
      > */}
      {/* <li className="pag flex flex-row grid lg:grid-cols-5 gap-4 w-100 md:grid-cols-3 sm:grid-cols-2">
          {projects?.length > 0 ? (
            projects?.map((video) => (
              <PrimaryCard key={video._id} video={video} />
            ))
          ) : (
            <h1>No projects found </h1>
          )}
        </li> */}
      {/* </ul> */}

      <div className="h-100 bg-white rounded-lg shadow-sm border px-3 pb-3">
        <div>
          <div className="grid lg:grid-cols-4 gap-5 mt-3 overflow-hidden">
            {projects.map((project) => (
              <div
                className={`${
                  project.status === "active" ? "bg-green-200" : "bg-red-200"
                } flex flex-col gap-1 cursor-pointer p-2 border shadow-md rounded rounded-3`}
                onClick={() => navigate(`/project/${project._id}`)}
              >
                {/* <div
                className="flex flex-col gap-1 cursor-pointer p-2 border shadow-sm rounded rounded-3"
                onClick={() => navigate(`/project/${project._id}`)}
              > */}
                <h1 className="text-blue-600 text-lg font-semibold text-ellipsis overflow-hidden">
                  {project.name}
                </h1>
                {/* <span className='text-gray-500 text-sm'>{project.description}</span> */}
                <div className="flex justify-between">
                  <span className="text-gray-600 text-sm">
                    Created At: {getDateFormat(project.createdAt)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600 text-sm text-ellipsis overflow-hidden">
                    Owner: {project.owner.name}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600 text-sm uppercase">
                    Status: {project.status}
                  </span>
                  <span className="text-gray-600 text-sm uppercase">
                    {secondsToHours(project.totalTimeSpent)}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {projects.length === 0 && (
            <div className="flex ">
              <h1 className="text-primary text-xl text-gray-500">
                {" "}
                No projects found
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Search;
