import moment from "moment";
export const getAntdFromInputRules = [
  {
    required: true,
    message: "Required",
  },
];
export const getDateFormat = (date) => {
  return moment(date).format("MMM Do YYYY, h:mm A");
};
export const getDateFormatWoTime = (date) => {
  return moment(date).format("ll");
};

export const dispSecondsAsMins = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsAfterMinutes = remainingSeconds % 60;
  return `${hours}h ${minutes}m ${remainingSecondsAfterMinutes}s`;
};

export const secondsToHours = (seconds) => {
  const hours = parseFloat(seconds / 3600).toFixed(2);
  return `${hours} hrs`;
}