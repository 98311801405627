import React from 'react';
import DashboardHeader from './DashboardHeader';


const DashboardPage = () => {

  return (
      <main className="container mt-3">
        <section>
          <DashboardHeader />
        </section>
      </main>
  );

};

export default DashboardPage;
