//Check for empty fields
export const isEmpty = (value) => {
    if(!value) return true
}
// Check for email formats
export const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
}
// check password length
export const isLength = (password) => {
    if(password.length <6) return true
    return false
}
// check for password match
export const isMatch = (password, confirmPassword) => {
if(password === confirmPassword) return true
    return false
}