import React, { useContext } from 'react'
import record from '../../icons/record.png'
import people from '../../icons/people.png'
import crm from '../../icons/crm.png'
import project from "../../icons/project.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';

const DashboardHeader = () => {
const navigate = useNavigate()
  return (
    <div id="features">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 pb-5">
        {/* <div className="md:w-2/3 lg:w-1/2"> */}
        <div className="">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 text-secondary"
          >
            <path
              fill-rule="evenodd"
              d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
              clip-rule="evenodd"
            />
          </svg> */}
          <div className="flex align-items-center justify-between gap-3 w-full">
            <div>
              <h2 className="my-8 text-2xl font-bold text-gray-700 dark:text-white md:text-4xl">
                Welcome to PepoCloud!
              </h2>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-orange text-white rounded-3"
                onClick={() => navigate(`/pricing`)}
              >
                Go to Pricing to Upgrade
              </button>
            </div>
          </div>
          <p className="text-gray-600 dark:text-gray-300">
            Click below to go to the app you want.
          </p>
        </div>
        <div className="mt-16 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
          <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
            <div className="relative space-y-8 py-12 p-8">
              <img
                src={record}
                className="w-12"
                width="512"
                height="512"
                alt="burger illustration"
              />

              <div className="space-y-2">
                <h5 className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
                  PepoRecord
                </h5>
                <p className="text-gray-600 dark:text-gray-300">
                  Record your screen, video and audio and share it with others
                  with a link.
                </p>
              </div>
              <Link
                to="https://app.peporecord.com/"
                className="flex items-center justify-between hover:text-secondary"
              >
                <span className="text-sm">Go to PepoRecord</span>
                <FaArrowRightLong />
              </Link>
            </div>
          </div>
          <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
            <div className="relative space-y-8 py-12 p-8">
              <img
                src={people}
                className="w-12"
                width="512"
                height="512"
                alt="burger illustration"
              />

              <div className="space-y-2">
                <h5 className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
                  PepoPeople
                </h5>
                <p className="text-gray-600 dark:text-gray-300">
                  Manage your employees' data, files, time sheets, payroll and
                  email them all.
                </p>
              </div>
              <Link
                to="https://app.pepopeople.com/"
                className="flex items-center justify-between hover:text-secondary"
              >
                <span className="text-sm">Go to PepoPeople</span>
                <FaArrowRightLong />
              </Link>
            </div>
          </div>
          <div className="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
            <div className="relative space-y-8 py-12 p-8">
              <img
                src={crm}
                className="w-12"
                width="512"
                height="512"
                alt="burger illustration"
              />

              <div className="space-y-2">
                <h5 className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
                  PepoCRM
                </h5>
                <p className="text-gray-600 dark:text-gray-300">
                  Manage your clients, leads, calls, contacts, vendors and
                  products' data in one place.
                </p>
              </div>
              <Link
                to="https://app.pepocrm.com/"
                className="flex items-center justify-between hover:text-secondary"
              >
                <span className="text-sm">Go to PepoCRM</span>
                <FaArrowRightLong />
              </Link>
            </div>
          </div>
          <div className="group relative bg-gray-50 dark:bg-gray-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
            <div className="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white dark:group-hover:bg-gray-800">
              <img
                src={project}
                className="w-12"
                width="512"
                height="512"
                alt="burger illustration"
              />

              <div className="space-y-2">
                <h5 className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
                  PepoSmart
                </h5>
                <p className="text-gray-600 dark:text-gray-300">
                  Manage your projects, record time, create tasks and assign
                  them to your people.
                </p>
              </div>
              <Link
                to="https://app.peposmart.com/"
                className="flex items-center justify-between hover:text-secondary"
              >
                <span className="text-sm">Go to PepoSmart</span>
                <FaArrowRightLong />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
