import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../App";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as HomeIcon } from "../icons/logo.svg";
import { ReactComponent as CreditCardIcon } from "../icons/credit-card.svg";
import { ReactComponent as UserIcon } from "../icons/user.svg";
import { ReactComponent as LogoutIcon } from "../icons/log-out.svg";
import { instance as axios } from "../api/axiosConfig";
import Avatarr from "./Avatar";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  SetIsLoggedIn,
  SetToken,
  SetUser,
} from "../redux/usersSlice";

const NavbarPrimary = () => {
  const [navbar, setNavbar] = useState(false);
  const { sidebar, setShowSidebar } = useContext(Sidebar);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { stripePlanInfo, token, user, notifications, reRender } = useSelector(
    (state) => state.users
  );

  const [portalUrl, setPortalUrl] = useState("");
  const hideNavbarPrimary =
    location.pathname === "/signin" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgotpassword";

  if (hideNavbarPrimary) {
    return null;
  }

  if (!user) {
    return null;
  }

  const handleClick = async () => {
    try {
      Cookies.remove("_apprftoken");
      Cookies.remove("token");
      await axios.get(`/v1/auth/signout`);
      dispatch(SetIsLoggedIn(false));
      dispatch(SetToken(""));
      dispatch(SetUser([]));
      localStorage.removeItem("_appSignin");
      window.location = "/signin";
      // navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const customerId = stripePlanInfo?.customerId || "";
  const handleBillingClick = async () => {
    // console.log("name is", userId)
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/create-customer-portal-session`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customerId }),
      }
    );
    const data = await response.json();
    setPortalUrl(data.url);
  };

  if (portalUrl) {
    window.location.href = portalUrl;
  }

  const changeBackground = () => {
    if (window.scrollY >= 180) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // To hide the sidebar by default for mobile screens
  // const hideSidebar = () => {
  //   if (window.innerWidth < 2640) {
  //     setShowSidebar(!sidebar);
  //   }
  // };

  // hideSidebar()

  window.addEventListener("scroll", changeBackground);
  // To hide the sidebar by default for mobile screens
  // window.addEventListener("resize", hideSidebar);

  return (
    <>
      {/* Desktop */}

      <header className={`sticky-top bg-secondary ${navbar && "bg-secondary"}`}>
        <nav className="py-2">
          <div className="px-4">
            <div className="d-flex align-items-center ">
              <div
                className="me-auto d-flex align-items-center cursor-pointer "
                onClick={() => navigate("/")}
              >
                <ul className="nav mt-1">
                  <li>
                    <button
                      type="button"
                      onClick={() => setShowSidebar(!sidebar)}
                      className="link icon-size-small"
                    >
                      <span>
                        <HomeIcon className="text-white mt-1" />
                      </span>
                    </button>
                  </li>
               
                </ul>
             
              </div>
           
              <div className="nav-links">
                <ul className="nav align-items-center gap-1 gap-sm-3">
                  <li>
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="..."
                        className="text-white d-flex align-items-center"
                      >
                        <div className="d-flex align-items-center small d-block h-100 w-100 rounded rounded-9">
                          <span className="icon-size-1 rounded rounded-3 me-2">
                            <Avatarr />
                          </span>
                          <span>{user?.name}</span>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="mb-2"
                          as={Link}
                          onClick={handleBillingClick}
                        >
                          {/* To hide 'billing' Dropdown button for free plan */}
                          <div
                            className={`${
                              stripePlanInfo === null ? "hidden" : ""
                            }`}
                          >
                            <div className="d-flex">
                              <span className="icon-size-very-small me-1">
                                <CreditCardIcon />
                              </span>
                              <span>Billing</span>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="mb-2" as={Link} to="/account">
                          <div className="d-flex">
                            <span className="icon-size-very-small me-1">
                              <UserIcon />
                            </span>
                            <span>Account</span>
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item as={Link} onClick={handleClick}>
                          <div className="d-flex">
                            <span className="icon-size-very-small me-1">
                              <LogoutIcon />
                            </span>
                            <span>Logout</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Mobile */}
    </>
  );
};

export default NavbarPrimary;
