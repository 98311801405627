import { instance as axios} from "./api/axiosConfig";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  SigninPage,
  SignupPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ActivateAccountPage,
} from "./components/auth";

import React from "react";
import DashboardPage from "./pages/home/DashboardPage";
import NavbarPrimary from "./components/NavbarPrimary";
import SidebarPrimary from "./components/SidebarPrimary";
import Search from "./components/Search";
import ErrorPage from "./pages/Error";
import Pricing from "./components/Pricing";
import StripeCustomerPortal from "./components/StripeCustomerPortal";

import {
  SetStripePlanInfo,
} from "./redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "./components/LoadingSpinner";
import Profile from "./pages/settings/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";


export const Sidebar = React.createContext();
export const Banner = React.createContext();
export const PepeCloud = React.createContext();

function App() {
  const [sidebar, setShowSidebar] = useState(true);
  const [pepeCloud, setPepeCloud] = useState(false);
  const { token, reRender } = useSelector(
    (state) => state.users
  );
  const { loading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();

  // This is to get the stirpe information at the start and store them in the global context
  useEffect(() => {
      const getStripePlan = async () => {
     const res = await axios.get('/getstripeplaninfo');
        console.log("stripe Plan info", res.data);
        dispatch(SetStripePlanInfo(res.data));
      };
      getStripePlan();
  
  }, [ token]);



  return (
    <>
      {loading && <LoadingSpinner />}
      <Sidebar.Provider value={{ sidebar, setShowSidebar }}>
        <PepeCloud.Provider value={{ pepeCloud, setPepeCloud }}>
          <BrowserRouter>
            <main className="min-vh-100 d-flex flex-column">
              <div className="d-flex flex-column flex-sm-row flex-fill">
                {/* <SidebarPrimary /> */}
                <div className="d-flex flex-column flex-fill">
                  <NavbarPrimary />
                  <Routes>
                    <Route
                      index
                      element={
                        <ProtectedRoute>
                          <DashboardPage />
                          {/* <Pricing /> */}
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/signin"
                      element={
                        <PublicRoute>
                          <SigninPage />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/signup/*"
                      element={
                        <PublicRoute>
                          <SignupPage />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/search"
                      element={
                        <ProtectedRoute>
                          <Search />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/forgotpassword"
                      element={
                        <PublicRoute>
                          <ForgotPasswordPage />
                        </PublicRoute>
                      }
                    />

                    <Route
                      path={`/reset-password/:token`}
                      element={
                        <PublicRoute>
                          <ResetPasswordPage />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path={`/activate/:activation_token`}
                      element={
                        <PublicRoute>
                          <ActivateAccountPage />
                        </PublicRoute>
                      }
                    />

                    <Route
                      path="/account"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/pricing"
                      element={
                        <ProtectedRoute>
                          <Pricing />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/billing"
                      element={
                        <ProtectedRoute>
                          {" "}
                          <StripeCustomerPortal />
                        </ProtectedRoute>
                      }
                    />

                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </div>
              </div>
            </main>
          </BrowserRouter>
        </PepeCloud.Provider>
      </Sidebar.Provider>
    </>
  );
}

export default App;
