import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    user: null, // user data like name, avatar and email
    allUsers: [],
    notifications: [],
    isLoggedIn: false,
    token: "", // access token
    stripePlanInfo: [], // This will have all the info about stripe: userId, customerId, product, price, payment_status. We sent this in App.js
    totalStorageUsedinMB: 0,
    reRender: 0,
  },
  reducers: {
    SetUser(state, action) {
      state.user = action.payload;
    },
    SetAllUsers(state, action) {
      state.allUsers = action.payload;
    },
    SetNotifications(state, action) {
      state.notifications = action.payload;
    },
    SetIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    SetToken(state, action) {
      state.token = action.payload;
    },
    SetStripePlanInfo(state, action) {
      state.stripePlanInfo = action.payload;
    },
    SetReRender(state, action) {
      state.reRender = action.payload;
    },
    SetTotalStorageUsedinMB(state, action) {
      state.totalStorageUsedinMB = action.payload;
    },
  },
});

export const {
  SetUser,
  SetAllUsers,
  SetNotifications,
  SetIsLoggedIn,
  SetToken,
  SetStripePlanInfo,
  SetTotalStorageUsedinMB,
  SetReRender,
  SetToRenderPredataOnce,
} = usersSlice.actions;

export default usersSlice.reducer;
