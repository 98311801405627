import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./styles/main.scss";
import store from "./redux/store";
import { Provider } from "react-redux";

// Import Swiper styles
import "swiper/css";

// Import rc-slider styles
import "rc-slider/assets/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_G_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </Provider>
  // </React.StrictMode>
);
