import usersReducer from './usersSlice'
import loadersReducer from './loadersSlice'
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
    reducer: {
        users: usersReducer,
        loaders: loadersReducer
    }
})
export default store