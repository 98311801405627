import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../../src/signinlogo.png";
import { instance as axios } from "../../api/axiosConfig";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { message } from "antd";

const ActivateAccount = () => {
  const navigate = useNavigate();
  // activation_token will be from the link in   <Route path="/api/v1/auth/activate/:activation_token" element={<ActivateAccountPage />}/> in App.js
  // useParams will take the activation_token from the link that we sent to email
  const { activation_token } = useParams();

  useEffect(() => {
    if (activation_token) {
      const activateUser = async () => {
        try {
          const res = await axios.post(`/v1/auth/activation`, {
            activation_token,
          });
          
        } catch (error) {
          message.error(error.response.data.msg)

          console.log(error.response.data.msg);
        }
      };
      activateUser();
    }
  }, [activation_token]);
  return (
    <>

      <section className="h-full gradient-form bg-gray-200 ">
        <div className="container py-8 px-6 h-full lg:max-w-[700px] md:max-w-[500px] sm:max-w-[600px] mx-auto my-auto bg-gray-200">
          <div className="flex justify-center items-center flex-wrap min-h-screen g-6 text-gray-800 bg-gray-200">
            <div className="sm:w-6/10">
              <div className="block bg-white shadow-lg rounded-lg">
                <div className="lg:flex lg:flex-wrap g-0">
                  <div className="lg:w-full px-4 md:px-0">
                    <div className="md:p-12 md:mx-6 mx-6 p-5">
                      <div className="text-center pt-3">
                        <img className="mx-auto w-20" src={logo} alt="logo" />
                        <h4 className="text-xl font-semibold mt-1 mb-12 pt-1">
                          Congrats! Your account is now{" "}
                          <span className="text-green-600 font-semibold">
                            activated.
                          </span>
                        </h4>
                      </div>
                      <form>
                        <div className="text-center pt-1 mb-12 pb-1">
                          <button
                            className="inline-block px-6 py-2.5 bg-[#001C42] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                            type="button"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                            onClick={() => navigate("/signin")}
                          >
                            Login to your account
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ActivateAccount;
