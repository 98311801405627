import React, { useContext, useState } from "react";
import { instance as axios } from "../api/axiosConfig";
import { useSelector } from "react-redux";

const CustomerPortalButton = () => {
  const { stripePlanInfo } = useSelector((state) => state.users);

  const [portalUrl, setPortalUrl] = useState("");
  const customerId = stripePlanInfo.customerId;

  const handleClick = async () => {
    // console.log("name is", userId)
    const response = await axios.post(`/create-customer-portal-session`, {
      customerId,
    });
    const data = await response.json();
    setPortalUrl(data.url);
  };

  if (portalUrl) {
    window.location.href = portalUrl;
  }

  return <button onClick={handleClick}>Access customer portal</button>;
};

export default CustomerPortalButton;
