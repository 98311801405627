import Input from "../../components/Input";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import React, { useRef, useState } from "react";
import { instance as axios } from "../../api/axiosConfig";
import { isLength } from "../../components/helper/Validate";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { SetUser } from "../../redux/usersSlice";

const initialState = {
  name: "",
  password: "",
};

const AccountSettingsPage = () => {
  const [showModal, setShowModal] = useState("");
  const [visible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.users);
  const [avatar, setAvatar] = useState(false);
  const [data, setData] = useState(initialState);
  const dispatch = useDispatch()
  const { name, password } = data;

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const UpdateInfo = async () => {
    try {
      const res = await axios.patch(`/v1/auth/user_update`, {
        name: name ? name : user.name, // if the user updates his name, we'll show the new name else we will use the existing name
        avatar: avatar ? avatar : user.avatar,
      });

      // Since we updated the user, we want to get the new user information from the backend to display it here
      const updatedUser = await axios.get('/v1/auth/userInfo');
      // Update the global state of the new user info so when we retrieve the user info, it will give us the latest info
      dispatch(SetUser(updatedUser.data));
      console.log("message is" + res.data.msg);
      return message.success(res.data.msg);
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  const updatePassword = async () => {
    // check password length
    if (isLength(password))
      return message.error("Password must be at least 6 characters");
    try {
      const res = await axios.post('/v1/auth/reset_pass', { password });
      return message.success(res.data.msg);
    } catch (error) {
      return message.error(error.res.data.msg);
    }
  };

  // To clear up the form after the submission successful
  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, password: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name || avatar) {
      UpdateInfo();
    }
    if (password) {
      updatePassword();
       //handleReset(); // This will clear out the password field after submission
    }
  };

  return (
    <>
      <main className="py-4 py-xl-5 container">
        <div className="h-100 bg-white rounded-lg shadow-sm border p-4">
          <div className="px-0 col-12 col-sm-11 col-md-8 col-lg-7 col-xl-6">
            <ul className="nav flex-column gap-3">
              <li>
                <h4 className="mb-0">Account</h4>
              </li>

              <li>
                <label className="mb-0">
                  Your login email address and password.
                </label>
              </li>
              <form onSubmit={handleSubmit}>
                <li>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="fw-bold" htmlFor="name">
                        Name
                      </label>
                      <Input
                        type="text"
                        text="Name"
                        name="name"
                        placeholder="name"
                        defaultValue={user?.name}
                        handleChange={handleChange}
                        className="form-control form-control-lg bg-white"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="d-block fw-bold">
                        Email{" "}(This field can't be edited)
                      </label>

                      <Input
                        type="text"
                        text="Email"
                        name="email"
                        placeholder="email"
                        handleChange={handleChange}
                        defaultValue={user?.email}
                        disabled
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="d-block fw-bold">Password</label>

                      <Input
                        name="password"
                        type={visible ? "text" : "password"}
                        icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
                        text="Password"
                        handleClick={handleClick}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="border-top pt-3 text-end">
                    <div className="nav gap-3">
                      <button
                        type="submit"
                        className="btn btn-primary shadow-sm rounded-3"
                      >
                        <span>Save Changes</span>
                      </button>
                    </div>
                  </div>
                </li>
              </form>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
};

export default AccountSettingsPage;
