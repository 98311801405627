import React, {  useState } from "react";
import logo from "../../../src/signinlogo.png";

import { AiOutlineGoogle } from "react-icons/ai";
import Input from "../Input";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty, isEmail, isLength } from "../helper/Validate";
import { instance as axios } from "../../api/axiosConfig";
import { GoogleLogin } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { SetIsLoggedIn } from "../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { SetLoading } from "../../redux/loadersSlice";
import { message } from "antd";

const initialState = {
  name: "",
  email: "",
  password: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.loaders);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const { name, email, password } = data;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  // When you click signup button
  const register = async (e) => {
    e.preventDefault();
    // check fields
    if (isEmpty(name) || isEmpty(password) || isEmpty(email))
      return message.error("Please fill in all fields");

    //Check email
    if (!isEmail(email))
      return message.error("Please enter a valid email address");

    // Check password
    if (isLength(password))
      return message.error("Password must be at least 6 characters");
    try {
      dispatch(SetLoading(true));
      const res = await axios.post(`/v1/auth/register`, {
        name,
        email,
        password,
      });
      dispatch(SetLoading(false));
      // console.log(name, email)
      // console.log(res.data.msg)
      message.success(res.data.msg);
    } catch (err) {
      console.log(err);
      dispatch(SetLoading(false));
      message.error(err.response.data.msg);
    }
    handleReset();
  };

  // To clear up the form after the submission successful
  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, name: "", email: "", password: "" });
  };

  const googleSuccess = async (res) => {
    // If the sign in success, google will give us tokenId
    //console.log(res) // When i console logged it, I found the token is in 'credential'
    const token = res?.credential;
    try {
      dispatch(SetLoading(true));
      let response = await axios.post(`/v1/auth/google_signin`, {
        tokenId: token,
      });
      dispatch(SetLoading(false));
      Cookies.set("_apprftoken", response.data.token, {
        expires: 7,
        path: "/",
      });
      localStorage.setItem("_appSignin", true);
      dispatch(SetIsLoggedIn(true));
    } catch (error) {
      message.error(error.response.data.msg);
       dispatch(SetLoading(false));
    }
  };

  const googleError = () => {
    message.error("There was an error signing in, please try again later");
     dispatch(SetLoading(false));
  };

  return (
    <section className="h-full gradient-form bg-gray-200 align-middle pt-20 pb-5">
      <div className="container py-8 px-6 h-full lg:max-w-[1200px] md:max-w-[600px] sm:max-w-[600px] mx-auto my-auto">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="xl:w-10/12">
            <div className="block bg-white shadow-lg rounded-lg">
              <div className="lg:flex lg:flex-wrap g-0">
                <div className="lg:w-6/12 px-4 md:px-0">
                  <div className="md:p-12 md:mx-6">
                    <div className="text-center pt-3">
                      <img className="mx-auto w-20" src={logo} alt="logo" />
                      <h4 className="text-xl font-semibold mt-1 mb-12 pt-1">
                        Welcome to PepoCloud's Family!
                      </h4>
                    </div>
                    <form onSubmit={register}>
                      <p className="mb-4 text-center"></p>

                      <GoogleLogin
                        className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-md px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2 w-full"
                        clientId={process.env.REACT_APP_G_CLIENT_ID}
                        //  Since we are using a custom button, we use 'render' method
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-md px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2 min-w-full mx-auto cursor-pointer"
                          >
                            <div className="text-lg mr-2 -ml-1">
                              <AiOutlineGoogle />
                            </div>
                            Sign in with Google
                          </button>
                        )}
                        cookiePolicy={"single_host_origin"}
                        // If it's successful, we will fire googleSuccess function
                        onSuccess={googleSuccess}
                        onError={googleError}
                      />

                      <div className="text-center my-3">
                        <p>or </p>
                      </div>

                      <div className="mb-4">
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          handleChange={handleChange}
                        />
                      </div>
                      <div className="mb-4">
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          handleChange={handleChange}
                        />
                      </div>
                      <div className="mb-4">
                        <Input
                          name="password"
                          handleChange={handleChange}
                          type={visible ? "text" : "password"}
                          placeholder="Password"
                          icon={
                            visible ? <MdVisibility /> : <MdVisibilityOff />
                          }
                          handleClick={handleClick}
                        />
                      </div>

                      <div className="text-center pt-1 mb-12 pb-1">
                        <button
                          className="inline-block px-6 py-2.5 bg-[#001C42] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                          type="submit"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                          loading={loading}
                        >
                          Sign Up
                        </button>
                        <Link
                          to="/forgotPassword"
                          className="text-gray-500"
                          href="#!"
                        >
                          Forgot password?
                        </Link>
                      </div>
                      <div className="flex items-center justify-between pb-6">
                        <p className="mb-0 mr-2">Already have an account?</p>
                        <button
                          type="button"
                          className="inline-block px-6 py-2 border-2 border-[#001C42] text-[#001C42] font-medium text-xs leading-tight uppercase rounded hover:bg-[#001C42] hover:text-white hover:bg-opacity-2 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                          onClick={() => navigate("/signin")}
                        >
                          Log in
                        </button>
                      </div>
                      <div>
                        <p className="mt-2 text-center text-sm text-gray-400">
                          We’re committed to your privacy. We never sell or
                          share the information you provide to third parties.
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-[#001C42]">
                  <div className="text-white px-4 py-6 md:p-12 md:mx-6">
                    <h4 className="text-xl font-semibold mb-6">
                      We are more than just a company
                    </h4>
                    <p className="text-sm">
                      This webapp is built with 1000s of hours of coding and
                      100s of sleepless nights. While we were coding each line,
                      we always have you in mind and made sure this will help
                      you effectively manage your business with minimal effort
                      from your side. We also used the best technologies
                      available to give you the utmost software quality
                      possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUp;
