import { TiTick } from "react-icons/ti";
import { useState } from "react";
import getStripe from "./getStripe";
import { instance as axios } from "../api/axiosConfig";
import { useSelector } from "react-redux";

const Pricing = () => {
  const [customerId, setCustomerId] = useState("");
  const { user, token } = useSelector((state) => state.users);

  const handle29Dollar = async (res, req) => {
    console.log("user email is ", user.email);
    const stripe = await getStripe();
    // event.preventDefault();

    // if (!stripe || !elements) {
    //   return;
    // }
    const priceId = process.env.REACT_APP_STRIPE_79DOLLAR_PRICE_ID;
    const product = "Essential";
    const price = "$29/month";
    const userEmail = user?.email;
    try {
      const { data } = await axios.post(`/checkout`, {
        priceId,
        userId: user?._id,
        userName: user?.name,
        userEmail,
        product,
        price,
        headers: {
          Authorization: token,
        },
      });
      console.log("data is " + data.customerId);
      const sessionId = data.id;
      if (data.url) {
        window.location.href = data.url;
      }
      // const result = await stripe.redirectToCheckout({
      //   sessionId,
      // });
      setCustomerId(data.customerId);
      console.log("Customer id is " + customerId);
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handle79Dollar = async (res, req) => {
    const stripe = await getStripe();

    const priceId = process.env.REACT_APP_STRIPE_99DOLLAR_PRICE_ID;
    const product = "Business";
    const price = "$79/month";

    try {
      const { data } = await axios.post(`/checkout`, {
        priceId,
        userId: user._id,
        userEmail: user.email,
        product,
        price,
        headers: {
          Authorization: token,
        },
      });
      console.log("data is " + data.customerId);
      const sessionId = data.id;
      if (data.url) {
        window.location.href = data.url;
      }
      // const result = await stripe.redirectToCheckout({
      //   sessionId,
      // });
      setCustomerId(data.customerId);
      console.log("Cusotmer id is " + customerId);
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <section className="relative z-20 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  Pricing Table
                </span>
                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                  Our Pricing Plan
                </h2>
                <p className="text-base text-body-color">
                  Choose the plan that fits your needs.
                </p>
              </div>
            </div>
          </div>

          <div className="-mx-4 flex flex-wrap justify-center">
            {/* <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12">
            <span className="mb-4 block text-lg font-semibold text-primary">
              Starter
            </span>
            <h2 className="mb-5 text-[42px] font-bold text-dark">
              $0
              <span className="text-base font-medium text-body-color">
                / forever
              </span>
            </h2>
            <p className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color">
              Perfect for trying out PepoRecord features.
            </p>
            <div className="mb-7">
              <div className="mb-1 flex leading-loose text-body-color">
                <span className="text-2xl font-medium text-body-color">
                  <TiTick className="mt-1" />
                </span>
                <p className="text-center">Record videos, audios, and Screen</p>
              </div>

              <div className="mb-1 flex leading-loose text-gray-400">
                <span className="text-lg font-medium text-body-color">
                  <RxCross2 className="mt-2 pr-0" />
                </span>
                <p className="text-center pl-2">Share videos with a link</p>
              </div>

              <div className="mb-1 flex leading-loose text-body-color">
                <span className="text-2xl font-medium text-body-color">
                  <TiTick className="mt-1" />
                </span>
                <p className="text-center">Download videos instantly</p>
              </div>

              <div className="mb-1 flex leading-loose text-body-color">
                <span className="text-2xl font-medium text-body-color">
                  <TiTick className="mt-1" />
                </span>
                <p className="text-center">Video library</p>
              </div>

              <div className="mb-1 flex leading-loose text-body-color">
                <span className="text-2xl font-medium text-body-color">
                  <TiTick className="mt-1" />
                </span>
                <p className="text-center">Knowledge base</p>
              </div>
            </div>
            <a
              href="javascript:void(0)"
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-primary hover:bg-primary hover:text-white"
            >
              Start Free
            </a>

   
          </div>
        </div> */}
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12">
                <span className="mb-4 block text-lg font-semibold text-primary">
                  Essential{" "}
                  <span className="border-solid border-2 border-green-400 rounded-lg bg-green-100 p-1 text-sm">
                    Popular
                  </span>
                </span>
                <h2 className="mb-5 text-[42px] font-bold text-dark">
                  $79
                  <span className="text-base font-medium text-body-color">
                    / month
                  </span>
                </h2>
                <p className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color">
                  Best for small teams
                </p>
                <div className="mb-7">
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">Add Unlimited Members</p>
                  </div>

                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">80GB Storage Space</p>
                  </div>
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoPeople</p>
                  </div>

                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoCRM</p>
                  </div>
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoSmart</p>
                  </div>
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoRecord</p>
                  </div>

                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">Standard Support</p>
                  </div>
                </div>
                <button
                  onClick={handle29Dollar}
                  className="block w-full rounded-md border border-primary bg-primary p-4 text-center text-base font-semibold text-white transition hover:bg-opacity-90"
                >
                  Start
                </button>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12">
                <span className="mb-4 block text-lg font-semibold text-primary">
                  Business
                </span>
                <h2 className="mb-5 text-[42px] font-bold text-dark">
                  $99
                  <span className="text-base font-medium text-body-color">
                    / month
                  </span>
                </h2>
                <p className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color">
                  Best for mid-sized teams
                </p>
                <div className="mb-7">
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">Add Unlimited Members</p>
                  </div>

                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">200GB Storage Space</p>
                  </div>
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoPeople</p>
                  </div>

                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoCRM</p>
                  </div>
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoSmart</p>
                  </div>
                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">PepoRecord</p>
                  </div>

                  <div className="mb-1 flex leading-loose text-body-color">
                    <span className="text-2xl font-medium text-body-color">
                      <TiTick className="mt-1" />
                    </span>
                    <p className="text-center">Priority Support</p>
                  </div>
                </div>
                <button
                  onClick={handle79Dollar}
                  className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-primary hover:bg-primary hover:text-white"
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Pricing;
