import axios from "axios";
import Cookies from "js-cookie";

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

instance.interceptors.request.use(
  async (config) => {
    let token = Cookies.get("token");
    config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => {
    return error;
  }
);

export const instanceGetToken = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

instanceGetToken.interceptors.request.use(
  async (config) => {
    let rf_token = Cookies.get("_apprftoken");
    config.headers.Authorization = `${rf_token}`;
    return config;
  },
  (error) => {
    return error;
  }
);
