import {Tabs} from 'antd'
import AccountSettingsPage from './AccountSettingsPage'

const Profile = () => {
  return (
    <Tabs defaultActiveKey="1" className="ml-5">
      <Tabs.TabPane tab="Accounts" key="1">
        <AccountSettingsPage />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default Profile