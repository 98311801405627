import React, { useContext, useEffect, useState } from 'react'
import logo from '../../src/logo.svg'
import axios from 'axios';
import { useSelector } from 'react-redux';
const Avatar = ({avatar}) => {
  const {user} = useSelector((state) => state.users);
  const [avatr, setAvatr] = useState(user?.avatar)

  // username was not renders only if we refresh the page, so we need to use useEffect and useState to render them automatically
  // useEffect(() => {
  //   const loadAvatar = async () => {
  //     const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/userInfo`, {
  //       headers: { Authorization: token }, // give access_token so our server will give you the user information
  //     });
  //     setAvatr(res.data.avatar);
  //   };
  //   loadAvatar();
  // });
  return (
    <div>
      {/* We do mention a default avatar image in userModal in backend which is uploaded in the cloudinary */}
      {/* <img src={avatar ? avatar : user?.avatar}></img> */}
      {/* <img src={avatr}></img> */}
      {/* // Hard coding here */}
      <img src="https://res.cloudinary.com/dcywne2o0/image/upload/v1676929223/avatar/avatar_cffk0v.jpg"></img> 
    </div>
  );
}

export default Avatar